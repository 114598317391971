<template style="background-color: aqua;">
  <v-card class="logincard mx-auto" elevation="0" rounded="xl" v-if="!isMobile">

    <v-row>
      <v-col xs="0" sm="0" md="5">
        <v-img src="../assets/logo2.png" width="40%" style="margin: 30px;"></v-img>
        <v-img src="../assets/img2.png" width="70%" class="mx-auto"></v-img>
      </v-col>

      <v-col xs="12" sm="12" md="7">
        <h1 style="margin-bottom: 5px; margin-top: 50px; text-align: left;">登录并授权</h1>
        <div v-if="!isitia" style="text-align: left;"><strong
            style="color: rgb(129, 129, 129); font-weight:normal;">第三方服务</strong> {{ appname }}</div>
        <div v-if="isitia" style="text-align: left;"><strong
            style="color: rgb(129, 129, 129); font-weight:normal;">互联科创官方服务</strong> {{ appname }}
        </div>

        <v-text-field label="学号或手机号" style="width: 90%; margin-top: 20px;" v-model="userid" :rules="[required]"
          prepend-inner-icon="mdi-account"></v-text-field>
        <v-text-field label="密码" style="width: 90%;" v-model="password" :rules="[required]" prepend-inner-icon="mdi-lock"
          type="password"></v-text-field>

        <div style="display: flex; justify-content: space-between; align-items: center; width: 90%;">
          <div>
            <v-btn @click="login()" style="width: 100px; margin-bottom: 50px; margin-right: 20px; margin-top: 10px;"
              size="large" elevation="0" rounded="xl" color="blue-darken-1">登录</v-btn>
            <v-btn style="width: 100px; margin-bottom: 50px; margin-top: 10px;" size="large" elevation="0" rounded="xl"
              color="grey-lighten-3" @click="$router.push('/register')">注册</v-btn>
          </div>
          <v-btn style="width: 100px; margin-bottom: 50px; margin-top: 10px;" size="large" elevation="0" rounded="xl" text
            @click="$router.push('/changepassword')">修改密码</v-btn>
        </div>


      </v-col>
    </v-row>



  </v-card>

  <v-card class="logincard mx-auto" elevation="0" rounded="xl" v-else style="margin-top: 20%; padding: 5px;">
    <v-img src="../assets/logo.png" width="30%" class="mx-auto" style="margin-top: 20px;"></v-img>
    <v-card-text style="padding-left: 20px; padding-right: 20px;">
      <h1 style="margin-bottom: 20px; text-align: center;">登录并授权</h1>
      <div v-if="!isitia" style="text-align: center;"><strong
          style="color: rgb(129, 129, 129); font-weight:normal;">第三方服务</strong> {{ appname }}</div>
      <div v-if="isitia" style="text-align: center;"><strong
          style="color: rgb(129, 129, 129); font-weight:normal;">互联科创官方服务</strong> {{ appname }}
      </div>

      <v-text-field label="学号或手机号" style="width: 100%; margin-top: 20px;" v-model="userid" :rules="[required]"
        prepend-inner-icon="mdi-account"></v-text-field>
      <v-text-field label="密码" style="width: 100%;" v-model="password" :rules="[required]" prepend-inner-icon="mdi-lock"
        type="password"></v-text-field>

      <v-btn @click="login()" style="width: 140px; margin-bottom: 20px; margin-top: 10px;"
        size="large" elevation="0" rounded="xl" color="blue-darken-1">登录</v-btn>
    </v-card-text>




    <v-card-actions>
      <v-btn size="large" elevation="0" rounded="xl" text @click="$router.push('/changepassword')">修改密码</v-btn>
      <v-btn size="large" elevation="0" rounded="xl" text @click="$router.push('/register')">注册</v-btn>
    </v-card-actions>

  </v-card>
  <!-- <v-card class="authcard mx-auto" elevation="3">
    <v-card-title>你将授权登录</v-card-title>
    <v-card-subtitle v-if="!isitia">第三方服务</v-card-subtitle>
    <v-card-subtitle v-if="isitia">互联科创官方服务</v-card-subtitle>
    <v-card-text>{{ appname }}</v-card-text>
  </v-card> -->
</template>

<style>
.logincard {
  width: 1000px;
  margin-top: 25vh;
  text-align: center;
}

.authcard {
  width: 400px;
  margin-top: 20px;
}

@media (max-width: 480px) {
  .logincard {
    width: 90%;
    margin-top: 5vh;
    text-align: center;
  }

  .authcard {
    width: 90%;
    margin-top: 20px;
  }
}
</style>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'HomeView',

  components: {

  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile); // 组件销毁时移除事件监听
  },

  mounted() {
    var appid = this.$route.query.appid
    if (appid == undefined) {
      this.$router.push('/error?info=appid为空')
      return
    }

    var callback_url = this.$route.query.callback_url
    if (callback_url == undefined) {
      this.$router.push('/error?info=回调地址为空')
      return
    }

    var message = this.$route.query.message

    var requestData = {
      appid: appid
    }

    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile); // 当窗口大小改变时重新检测

    axios.post('https://authapi.gcacitia.com/auth/getappname', requestData)
      .then(response => {
        // 请求成功处理
        this.appname = response.data.name
        this.isitia = response.data.isitia
        this.appid = appid
        this.callback_url = callback_url
        this.message = message
      })
      .catch(error => {
        // 请求失败处理
        this.$router.push('/error?info=appid无效')
        return
      });

  },

  methods: {
    required(v) {
      return !!v || '此项不得为空'
    },

    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // 判断屏幕宽度是否小于等于768px
    },

    login() {
      var requestData = {
        userid: this.userid,
        password: this.password,
        appid: this.appid
      }
      axios.post('https://authapi.gcacitia.com/auth/login', requestData)
        .then(response => {
          // 请求成功处理
          console.log('登录成功');
          console.log('Token:', response.data.token);
          window.location.href = `${this.callback_url}?token=${response.data.token}&message=${this.message}`
        })
        .catch(error => {
          alert(`登录失败，${error.response.data.error}`)
        });
    },
  },

  data: () => ({
    appid: '',
    callback_url: '',
    message: '',
    appname: '',
    isitia: 0,

    userid: '',
    password: '',

    isMobile: false,

  }),
});
</script>
