<template>
  <v-app style="background-color: aliceblue;">
    <v-main>
      <router-view />
    </v-main>
    <v-footer style="background-color: aliceblue; margin-top: 10px;">
      <v-row justify="center" no-gutters>
        <v-col class="text-center mt-4" cols="12">
          {{ new Date().getFullYear() }} — <strong>互联网科技创新协会</strong>
        </v-col>
        <v-col class="text-center" cols="12">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="icp-link"
            style="color: black; text-decoration: none">粤ICP备2023043412号-1</a>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
